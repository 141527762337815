import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { motion, useInView } from 'framer-motion';
import './Home.css';
import healthcareImage from '../assets/Innovation-1.jpg';
import CognitiveBusinessAutomation from '../assets/sol/sol-robotic-process-automation.jpg';
import Advanced_Analytics from '../assets/sol/Advanced-Analytics.png';
import EDICT from '../assets/sol/EDI-for-healthcare.jpg';
// import Masters_of_AI_Innovation from '../assets/sol/sol-GenAI.jpg';
import Masters_of_AI_Innovation from '../assets/sol/Generative AI applications using LLMs and their frameworks.png';
import EMR_Integration from '../assets/sol/interoperability-EMR-FHIR-EPIC.png';
import mlops from '../assets/MLOps.png';
import nlp from '../assets/NLP.jpg';
import pexels from '../assets/pexels-tima-miroshnichenko-6011598.jpg';
import ProfileCard from './ProfileCard'; // Use ProfileCard as per your existing implementation
import content from './Content';
import Footer from './footer';
import './Services.css';

const currentYear = new Date().getFullYear();

const Solutions = () => {
    const { id } = useParams(); // Get the id from the URL
    const sectionRefs = useRef({}); // Refs for scrolling

    // Scroll to specific solution section if ID is provided
    useEffect(() => {
        if (id && sectionRefs.current[id]) {
            sectionRefs.current[id].scrollIntoView({ behavior: 'smooth' });
        }
    }, [id]);

    // Define solutions content
    const solutionContents = [
        {
            id: 'Cognitive Business Automation',
            title: "Cognitive Workflow Innovation",
            description: (
                <>
                    <strong>Agentic Automation:</strong> Automate decision-making tasks with intelligent software bots that autonomously adapt to workflows and manage approval processes, compliance checks, and risk mitigation.<br />
                    <strong>Intelligent Document Processing:</strong> Extract, classify, and validate data from documents like invoices or contracts with AI and ML algorithms.<br />
                    <strong>Document Understanding:</strong> Leverage natural language models to summarize reports, identify key details, and analyze unstructured data.<br />
                    <strong>Human in the Loop:</strong> Maintain a balance between automation and human input for critical processes such as document review and escalations.<br />
                    <strong>Process Mining:</strong> Analyze workflows to identify bottlenecks and unlock efficiencies through data-driven insights.<br />
                    <strong>Communication Mining:</strong> Automate customer communication routing and query prioritization by analyzing patterns across emails and chats.<br />
                    <strong>Approval Process Automation:</strong> Simplify multi-step approval workflows for invoices, purchase orders, and expense authorizations.
                </>
            ),
            image: CognitiveBusinessAutomation,
        },
        {
            id: 'Masters of AI Innovation',
            title: "Empowering Innovation Together 🚀 Powered by AI",
            description: (
                <>
                    <strong>Generative AI Applications:</strong> Empower content creation with AI-generated text, images, and videos in real-time. Enhance product design by utilizing AI tools for creating 3D models or prototypes. Revolutionize customer experiences with personalized recommendations and AI-powered chatbots.<br />
                    <strong>Agentic AI Use Cases:</strong> Leverage AI agents to autonomously manage workflows, make real-time decisions, and optimize processes in areas like healthcare, customer support, and supply chain management. Drive efficiency through AI-enabled decision-making architectures.<br />
                    <strong>Creative Ideation:</strong> Utilize AI for generating storyboards, scripts for advertisements, and artistic content for media. Empower developers with AI-assisted code generation and debugging tools, significantly enhancing productivity.<br />
                    <strong>Data Analysis & Cybersecurity:</strong> Transform complex datasets into actionable insights with AI-powered visualization tools. Monitor and respond to threats in real-time with GenAI-driven cybersecurity solutions.<br />
                    <strong>Automation with GenAI:</strong> Streamline operations through the automation of customer support workflows, invoice processing, and supply chain logistics using generative AI models.<br />
                    <strong>Frameworks like LangChain, LangGraph, and LangSmith:</strong> Build sophisticated AI solutions by utilizing LangChain to connect APIs and LLMs for multi-step workflows. Use LangGraph for visualizing task transitions in multi-agent systems. Monitor and optimize AI performance with LangSmith's debugging and analytics tools.<br />
                    <strong>OpenAI Integration:</strong> Engage users with natural language models developed by OpenAI, delivering advanced conversational interfaces and robust problem-solving capabilities.
                </>
            ),
            image: Masters_of_AI_Innovation,
        },
        {
            id: 'Advanced Analytics',
            title: "Unlocking Insights with Advanced Analytics",
            description: (
                <>
                    <strong>BigData & Cloud Analytics:</strong> Leverage cutting-edge tools to manage and analyze massive datasets securely in the cloud. Transform your business operations by extracting actionable insights from diverse data sources.<br />
                    <strong>ETL/ELT Pipelines:</strong> Develop robust pipelines to extract, transform, and load data efficiently, ensuring seamless data flow and accessibility.<br />
                    <strong>Business Rule Engine:</strong> Implement advanced business rule engines to automate decision-making processes and enforce compliance across operations.<br />
                    <strong>End-to-End Analytics:</strong> Complete analytics solutions from data integration (ETL), rule enforcement, and visualization to dashboard creation, ensuring a unified view of your business.<br />
                    <strong>Data Warehousing:</strong> Design and maintain scalable data warehouses for optimized storage and querying of structured data.<br />
                    <strong>Dashboarding:</strong> Create interactive dashboards that visualize key metrics in real-time, empowering smarter decision-making at all levels.<br />
                    <strong>Predictive Analytics:</strong> Utilize AI-driven models to forecast trends and outcomes, helping businesses prepare and adapt to future scenarios.
                </>
            ),
            image: Advanced_Analytics,
        },
        {
            id: 'EDI Conversion Tool 🚀 Powered By AI',
            title: "EDI-CT (EDI Conversion Tool) 🚀 Powered By AI",
            description: (
                <>
                    <strong>EDI Conversion:</strong> Convert complex electronic data interchange (EDI) files into human-readable formats with precision and accuracy.<br />
                    <strong>Custom Templates and Formats:</strong> Generate tailored EDI templates and formats to meet specific business requirements, enhancing usability and integration.<br />
                    <strong>Multi-Industry Support:</strong> Seamless EDI solutions for healthcare, finance, logistics, and other industries, ensuring smooth data exchanges.<br />
                    <strong>Real-Time Integration:</strong> Ensure data consistency across departments with real-time EDI integrations, eliminating redundancies and boosting productivity.<br />
                    <strong>Enhanced Productivity:</strong> Automate repetitive EDI tasks to save time, reduce errors, and improve operational efficiency.<br />
                    <strong>Secure Data Flow:</strong> Enable safe and reliable communication of sensitive business information through encrypted EDI pipelines.<br />
                </>
            ),
            image: EDICT,
        },
        {
            id: 'Advanced EMR Systems and Integration',
            title: "Interoperable EMR Platforms",
            description: (
                <>
                    <strong>Interoperability:</strong> Facilitate seamless sharing of patient records between healthcare providers, enabling improved collaboration and care.<br />
                    <strong>Real-Time Access:</strong> Provide healthcare professionals with instant access to critical patient information, ensuring timely and informed decisions.<br />
                    <strong>Compliance and Security:</strong> Meet industry standards for security and privacy with advanced data protection mechanisms.<br />
                    <strong>Clinical Decision Support:</strong> Support advanced decision-making capabilities with AI-enabled tools integrated into EMR systems.<br />
                    <strong>Custom Solutions:</strong> Tailor EMR platforms to meet the unique needs of your organization, maximizing efficiency and patient outcomes.<br />
                    <strong>EPIC and FHIR:</strong> Implement widely recognized EMR frameworks to ensure reliability, scalability, and standardized healthcare management.<br />
                </>
            ),
            image: EMR_Integration,
        },        
    ];

    return (
        <div className="solution-container">
            {solutionContents.map((item, index) => (
                <Card
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    description={item.description}
                    image={item.image}
                    reverse={index % 2 === 0} // Alternate layout
                />
            ))}
            <Footer />
        </div>
    );
    
};

const Card = ({ id, title, description, image, reverse }) => {
    const ref = useRef(null); // Create a ref for the card
    const isInView = useInView(ref, { once: true }); // Check if the card is in view

    return (
        <motion.div
            id={id}
            ref={ref} // Attach ref for in-view detection
            className={`solution-card ${reverse ? "reverse" : ""}`}
            // Slide from left or right
            initial={{ opacity: 0, x: reverse ? -100 : 100 }} 
            // Animate when in view
            animate={isInView ? { opacity: 1, x: 0 } : {}}
            transition={{
                duration: 0.8,
            }}
        >
            {/* Independent animations for image and content */}
            <motion.div
                className="solution-card-left"
                initial={{ opacity: 0, x: reverse ? -100 : 100 }}
                animate={isInView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.8 }}
            >
                <img src={image} alt={title} />
            </motion.div>

            <motion.div
                className="solution-card-right"
                initial={{ opacity: 0, x: reverse ? 100 : -100 }}
                animate={isInView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.8, delay: 0.2 }} // Slight delay for staggered effect
            >
                <h2>{title}</h2>
                <p>{description}</p>
            </motion.div>
        </motion.div>
    );
};

export default Solutions;


// import React, { useState, useRef, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import './Home.css';
// import healthcareImage from '../assets/Innovation-1.jpg';
// import solrpa from '../assets/sol/sol-robotic-process-automation.jpg';
// import bigdata from '../assets/Business_Data_Analytics.jpg';
// import edi from '../assets/EDI.jpg';
// import genai from '../assets/GenAI.jpg';
// import aiml from '../assets/AIML.jpg';
// import mlops from '../assets/MLOps.png';
// import nlp from '../assets/NLP.jpg';
// import pexels from '../assets/pexels-tima-miroshnichenko-6011598.jpg';
// import ProfileCard from './ProfileCard'; // Use ProfileCard as per your existing implementation
// import content from './Content';
// import Footer from './footer';
// import './Services.css';


// const currentYear = new Date().getFullYear();

// const Solutions = () => {
//     const { id } = useParams(); // Get the id from the URL
//     const sectionRefs = useRef({}); // Refs for scrolling


//     useEffect(() => {
//         if (id && sectionRefs.current[id]) {
//             sectionRefs.current[id].scrollIntoView({ behavior: 'smooth' });
//         }
//     }, [id]);
//     // Define all solutions
//     const solutionContents = [
//         {
//             id: 'sol-rpa',
//             title: "Cognitive Business Automation",
//             img_content: (
//                 <p>
//                 <br></br>
//                 <strong>Explore Our Solutions:</strong>
//                 <br />
//                 <br />
//                 <strong>Agentic Automation:</strong><br/>
//                 Automate decision-making tasks with intelligent software bots that act autonomously, adapting 
//                 to complex workflows and dynamic business conditions. Let technology intelligently manage your 
//                 approval processes, compliance checks, and risk mitigation.
//                 <br />
//                 <br />
//                 <strong>Intelligent Document Processing:</strong><br/>
//                 Extract, classify, and validate data from complex documents like invoices, contracts, or medical 
//                 records using advanced AI and ML algorithms. Reduce manual intervention while enhancing data 
//                 accuracy across industries such as healthcare and finance.
//                 <br />
//                 <br />
//                 <strong>Document Understanding:</strong><br/>
//                 Leverage natural language models to analyze and comprehend unstructured data, enabling bots to 
//                 summarize reports, identify key details, and perform contextual tasks.
//                 <br />
//                 <br />                
//                 <strong>Human in the Loop:</strong><br/>
//                 Empower collaboration between AI and humans by keeping humans involved in critical checkpoints. 
//                 From document review to escalation processes, maintain balance between automation and human insight.
//                 <br />
//                 <br />                
//                 <strong>Process Mining:</strong><br/>
//                 Unlock hidden efficiencies in your workflows by analyzing end-to-end processes and identifying 
//                 bottlenecks. Optimize business operations with data-driven insights and automation recommendations.
//                 <br />
//                 <br />
//                 <strong>Communication Mining:</strong><br/>
//                 Analyze patterns in customer communications across emails, chats, and support systems to extract 
//                 actionable insights. Automate routing, prioritize queries, and enhance your customer support capabilities.
//                 <br />
//                 <br />                
//                 <strong>Approval Process Automation:</strong><br/>
//                 Simplify multi-step approval workflows by integrating RPA bots into systems for invoice approvals, purchase orders, and expense authorizations, ensuring compliance and speeding up processing times.

                

//                 </p>

//             ),
//             image: solrpa,
//         },
//         // {
//         //     id: 'bigdata',
//         //     title: <strong>Big Data & Cloud Analytics</strong>,
//         //     img_content: (
//         //         <p>
//         //         <br></br>
//         //         In today’s digital era, <strong>data</strong> is the backbone of every successful organization. 
//         //         Our <strong>BigData and Cloud Analytics</strong> services are designed to help you aggregate, 
//         //         analyze, and visualize vast volumes of data with unparalleled accuracy and efficiency.
//         //         <br />
//         //         <br />
//         //         We specialize in crafting scalable cloud solutions, enabling seamless data migration and 
//         //         management on platforms like <strong>AWS</strong>, <strong>Azure</strong>, and <strong>Google Cloud</strong>. 
//         //         Whether it’s building predictive models, optimizing business intelligence tools, or implementing 
//         //         real-time analytics, we provide end-to-end support tailored to your unique needs.
//         //         <br />
//         //         <br />
//         //         With our expertise, transform raw data into valuable insights that drive smarter, faster 
//         //         decision-making. Stay ahead of the competition by unlocking the true potential of your data.
//         //         </p>
//         //     ),
//         //     image: bigdata,
//         // },
//         // {
//         //     id: 'edi',
//         //     title: <strong>EDI X12 Converter as a Service</strong>,
//         //     img_content: (
//         //         <p>
//         //         <br></br>
//         //         <strong>EDI (Electronic Data Interchange)</strong> is the lifeline of seamless communication in 
//         //         industries like healthcare, supply chain, and logistics. Our <strong>EDI X12 Converter</strong> 
//         //         services simplify the processing and exchange of critical data such as healthcare claims, 
//         //         invoices, and shipping notices.
//         //         <br />
//         //         <br />
//         //         We offer a robust, scalable, and secure platform to translate, validate, and transmit EDI 
//         //         documents effortlessly. Our solutions ensure complete compliance with industry standards while 
//         //         optimizing data accuracy and reducing processing times. Whether you're a small business or a 
//         //         large enterprise, our EDI services are designed to meet your unique requirements. Simplify your 
//         //         workflows and enhance operational efficiency with us!
//         //         </p>
//         //     ),
//         //     image: edi,
//         // },
//         // {
//         //     id: 'aiml',
//         //     title: <strong>AIML Solutions</strong>,
//         //     img_content: (
//         //         <p>
//         //         <br></br>
//         //         Experience the transformative impact of <strong>AI and ML</strong> in solving real-world business 
//         //         challenges. Our <strong>AIML solutions</strong> span a wide range of applications, including 
//         //         predictive modeling, recommendation systems, fraud detection, and process automation.
//         //         <br />
//         //         <br />
//         //         From data preprocessing to model deployment, we handle every aspect of the AI lifecycle with 
//         //         precision and expertise. With cutting-edge algorithms and a keen understanding of your business 
//         //         needs, we deliver tailor-made solutions that provide actionable intelligence and measurable results. 
//         //         Let us help you redefine innovation and drive success with <strong>AIML</strong>.
//         //       </p>              
//         //     ),
//         //     image: aiml,
//         // },
//         // {
//         //     id: 'genai',
//         //     title: <strong>GenAI Application Development</strong>,
//         //     img_content: (
//         //         <p>
//         //         <br></br>
//         //         <strong>Generative AI</strong> is reshaping how we interact with technology, and we are at the 
//         //         forefront of this transformation. Our expertise in frameworks like <strong>LangChain</strong>, 
//         //         <strong>LangGraph</strong>, and <strong>LangSmith</strong> enables us to create powerful AI-driven 
//         //         applications capable of natural language understanding, creative content generation, and workflow automation.
//         //         <br />
//         //         <br />
//         //         From building intelligent chatbots to designing AI systems that generate highly personalized user 
//         //         experiences, our <strong>GenAI solutions</strong> are tailored to your business needs. Whether it's 
//         //         enhancing customer engagement or simplifying complex processes, we bring your ideas to life with 
//         //         <strong>GenAI</strong>.
//         //         </p>
//         //     ),
//         //     image: genai,
//         // },
//         // {
//         //     id: 'nlp',
//         //     title: <strong>Natural Language Models</strong>,
//         //     img_content: (
//         //         <p>
//         //         <br></br>
//         //         <strong>Natural Language Models (NLMs)</strong> are at the heart of modern AI applications, enabling 
//         //         human-like interactions and precise understanding of text and speech. We specialize in developing 
//         //         and deploying <strong>NLM solutions</strong> tailored to your specific needs.
//         //         <br />
//         //         <br />
//         //         From creating conversational agents and chatbots to enabling automated document summarization and 
//         //         sentiment analysis, our solutions enhance user engagement and operational efficiency. Whether it’s 
//         //         transforming customer service or improving workflow automation, our <strong>NLM expertise</strong> 
//         //         ensures meaningful impact and lasting results.
//         //         </p>
//         //     ),
//         //     image: nlp,
//         // },
//         // {
//         //     id: 'mlops',
//         //     title: <strong>MLOps for Model Monitoring</strong>,
//         //     img_content: (
//         //         <p>
//         //         <br></br>
//         //         <strong>MLOps (Machine Learning Operations)</strong> is critical for the sustained success of AI 
//         //         systems. Our <strong>MLOps solutions</strong> offer robust frameworks for monitoring, deploying, 
//         //         and governing both traditional and large language models (LLMs).
//         //         <br />
//         //         <br />
//         //         We provide end-to-end support, from setting up model pipelines to ensuring compliance with ethical 
//         //         and regulatory standards. With advanced monitoring tools, we track model performance, detect drifts, 
//         //         and implement governance protocols that align with your business objectives. Unlock the full potential 
//         //         of your AI investments with our <strong>MLOps expertise</strong>.
//         //         </p>
//         //     ),
//         //     image: mlops,
//         // },
//     ];

//     // // Scroll to the specific technology section if an ID is provided
//     // useEffect(() => {
//     //     if (id && sectionRefs.current[id]) {
//     //         sectionRefs.current[id].scrollIntoView({ behavior: 'smooth' });
//     //     }
//     // }, [id]);

//     return (
//         <div className="solution-container">

//             {solutionContents.map((item, index) => (
//                 <div
//                     key={item.id}
//                     id={item.id}
//                     ref={(el) => (sectionRefs.current[item.id] = el)} // Attach refs for scrolling
//                     // className={`technology-card ${index % 2 === 0 ? "reverse" : ""}`} // Add 'reverse' class for alternate layouts
//                     className={`solution-card ${index % 2 === 0 ? "reverse" : ""}`} // Alternate layout                    
//                 >
//                     {/* Content and Image Layout */}
//                     {/* Alternate Layouts */}                    
//                     {index % 2 === 0 ? (
//                         <>
//                             <div className="sol-card-content-left">
//                                 <img src={item.image} alt={item.title} />
//                             {/* </div>
//                             <div className="sol-card-content-right"> */}
//                                 <span>{item.title}</span>
//                                 {item.img_content}
//                             </div>
//                         </>
//                     ) : (
//                         <>
//                             <div className="sol-card-content-left">
//                                 <h2>{item.title}</h2>
//                                 {item.img_content}
//                             </div>
//                             <div className="sol-card-content-right">
//                                 <img src={item.image} alt={item.title} />
//                             </div>
//                         </>
//                     )}
//                 </div>
//             ))}

//             <Footer/>

//         </div>
//     );
// };

// export default Solutions;
