import React from "react";
import "./AboutUs.css";
import Footer from './footer';

function AboutUs() {
    return (
        <div className="about-us-container">
            <section className="about-hero">
                <h1>Empowering Innovation Through Technology</h1>
                <p>Crafting intelligent solutions to revolutionize business processes and elevate experiences.</p>
            </section>

            <section className="about-section">
                <h2>Our Vision</h2>
                <p>Our vision is to be at the forefront of technology-driven innovation, empowering businesses to achieve unprecedented efficiency, adaptability, and scalability. We strive to build solutions that not only address challenges but unlock new opportunities for our clients.</p>
            </section>

            <section className="about-section">
                <h2>Our Mission</h2>
                <p>We are dedicated to delivering high-quality, intelligent solutions across analytics, automation, data integration, and healthcare technology. By leveraging cutting-edge AI frameworks, interoperability platforms, and scalable architectures, we transform ideas into impactful products tailored to meet business needs.</p>
            </section>

            <section className="about-section">
                <h2>Who We Are</h2>
                <p>We are a dynamic team of technologists, innovators, and problem-solvers committed to creating solutions that redefine industries. With expertise in advanced analytics, Generative AI, Agentic AI, and interoperability frameworks, we bring unparalleled capabilities to every project we undertake.</p>
                <p>Our in-house developed tools and technologies—like <strong>EDICT</strong>, <strong>EMR Platforms (EPIC, FHIR)</strong>, and <strong>Advanced Analytics Solutions</strong>—enable businesses to thrive by simplifying workflows, enhancing decision-making, and delivering superior results.</p>
            </section>

            <section className="about-expertise">
                <h2>Our Expertise</h2>
                <ul>
                    <li><strong>Generative AI:</strong> Content creation, product design, and personalized customer experiences using advanced frameworks like LangChain, LangGraph, and LangSmith.</li>
                    <li><strong>RPA and Agentic AI:</strong> Driving cognitive business transformation through intelligent workflow solutions and process optimization.</li>
                    <li><strong>Analytics:</strong> ETL/ELT pipelines, data warehouses, business rule engines, and dashboarding solutions to leverage Big Data and cloud platforms.</li>
                    <li><strong>Healthcare Technology:</strong> Interoperable EMR platforms like EPIC and FHIR for secure, real-time access and advanced clinical decision-making.</li>
                    <li><strong>EDICT:</strong> Seamless EDI conversion to human-readable formats with tailored templates and secure data pipelines.</li>
                </ul>
            </section>

            <section className="about-values">
                <h2>Our Values</h2>
                <ul>
                    <li><strong>Innovation:</strong> Embracing emerging technologies to create cutting-edge solutions.</li>
                    <li><strong>Collaboration:</strong> Partnering with clients to co-create meaningful and impactful systems.</li>
                    <li><strong>Excellence:</strong> Delivering results that exceed expectations with precision and dedication.</li>
                    <li><strong>Integrity:</strong> Upholding trust and transparency in every interaction.</li>
                </ul>
            </section>

            <section className="about-contact">
                <h2>Get in Touch</h2>
                <p>Ready to transform your business with cutting-edge technology? <strong>Contact us today</strong> and let’s innovate together.</p>
            </section>

            <Footer/>
        </div>
    );
}

export default AboutUs;


// import React from 'react';

// const AboutUs = () => {
//   return (
//     <section>
//       <h2>About Us</h2>
//       <p>Our mission is to revolutionize healthcare with automation and analytics.</p>
//     </section>
//   );
// };

// export default AboutUs;
