// import React, { useState } from 'react';
// import './ProfileCard.css';

// function ProfileCard({ image, img_content }) {
//     const [showMore, setShowMore] = useState(false);

//     const toggleShowMore = (event) => {
//         event.preventDefault(); // Prevent page refresh
//         setShowMore((prevShowMore) => !prevShowMore); // Toggle state
//     };

//     return (
//         <div className="column">
//             <div className="card">
//                 <img src={image} alt="Profile Image" />
//                 <div className="card-content">
//                     <div
//                         className={`content ${showMore ? 'show-full' : 'show-truncated'}`}
//                         dangerouslySetInnerHTML={{ __html: img_content }}
//                     ></div>

//                     <a 
//                         href="#" 
//                         className="read-more-link"
//                         onClick={toggleShowMore}
//                     >
//                         {showMore ? 'Read Less →' : 'Read More →'}
//                     </a>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default ProfileCard;

// import React, { useState } from 'react';
// import './ProfileCard.css';

// function ProfileCard({ image, img_content }) {
//     const [showMore, setShowMore] = useState(false);

//     const toggleShowMore = (event) => {
//         event.preventDefault(); // Prevent page refresh
//         setShowMore((prevShowMore) => !prevShowMore); // Toggle state
//     };

//     // Determine content type (string or JSX)
//     const isStringContent = typeof img_content === 'string';

//     return (
//         <div className="column">
//             <div className="card">
//                 <img src={image} alt="Profile Image" />
//                 <div className="card-content">
//                     {/* Toggle content class based on showMore */}
//                     <div className={`content ${showMore ? 'show-full' : 'show-truncated'}`}>
//                         {img_content}
//                     </div>

//                     {/* "Read More / Read Less" link */}
//                     {(isStringContent || React.isValidElement(img_content)) && (
//                         <a 
//                             href="#"
//                             className="read-more-link"
//                             onClick={toggleShowMore}
//                         >
//                             {showMore ? 'Read Less →' : 'Read More →'}
//                         </a>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default ProfileCard;


// import React, { useState } from 'react';
// import './ProfileCard.css';

// function ProfileCard({ image, img_content }) {
//     const [showMore, setShowMore] = useState(false);

//     const toggleShowMore = (event) => {
//         event.preventDefault();
//         setShowMore(!showMore);
//     };

//     // Determine content type (string or JSX)
//     const isStringContent = typeof img_content === 'string';

//     // Truncate content if it's a string
//     const truncatedContent = isStringContent && img_content.length > 100 
//         ? `${img_content.substring(0, 100)}...` 
//         : img_content;

//     return (
//         <div className="column">
//             <div className="card">
//                 <img src={image} alt="Profile Image" />
//                 <div className="card-content">
//                     <div
//                         className={`content ${showMore ? 'show-full' : 'show-truncated'}`}
//                         // style={{
//                         //     textAlign: 'center', // Center align content
//                         //     overflow: showMore ? 'visible' : 'hidden',
//                         //     // display: '-webkit-box',
//                         //     WebkitBoxOrient: 'vertical',
//                         //     WebkitLineClamp: showMore ? 'none' : 3, // Limit to 3 lines when truncated
//                         // }}
//                     >
//                         {/* Render full or truncated content */}
//                         {/* {showMore ? img_content : truncatedContent} */}
//                         {/* {showMore ? (
//                             <div className="jsx-content">{img_content}</div>
//                         ) : (
//                             <div className="jsx-content">{truncatedContent}</div>
//                         )} */}
//                         {img_content}
//                     </div>


//                     {/* "Read More / Read Less" functionality */}
//                     {isStringContent || React.isValidElement(img_content) ? (
//                         <a 
//                             href="#"
//                             className="read-more-link"
//                             onClick={toggleShowMore}
//                         >
//                             {showMore ? 'Read Less →' : 'Read More →'}
//                         </a>
//                     ) : null}
                    
//                     {/* {isStringContent || React.isValidElement(img_content) ? (
//                         <a 
//                             href="#"
//                             className="read-more-link"
//                             onClick={toggleShowMore}
//                         >
//                             {showMore ? 'Read Less →' : 'Read More →'}
//                         </a>
//                     ) : null} */}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default ProfileCard;

// import React, { useState } from 'react';
// import './ProfileCard.css';

// function ProfileCard({ image, img_content }) {
//     const [showMore, setShowMore] = useState(false);

//     const toggleShowMore = (event) => {
//         event.preventDefault();
//         setShowMore(!showMore);
//     };

//     const isStringContent = typeof img_content === 'string'; // Check if content is a string

//     return (
//         <div className="column">
//             <div className="card">
//                 <img src={image} alt="Home page logo" />
//                 <div className="card-content">
//                     {/* Render content dynamically */}
//                     <div
//                         className={`content ${showMore ? 'show-full' : 'show-truncated'}`}
//                         style={{
//                             overflow: showMore ? 'visible' : 'hidden',
//                             display: '-webkit-box',
//                             WebkitBoxOrient: 'vertical',
//                             WebkitLineClamp: showMore ? 'none' : 3, // Clamp to 3 lines for truncated view
//                         }}
//                     >
//                         {img_content}
//                     </div>

//                     {/* Show "Read More" for both cases: string or JSX */}
//                     <a
//                         href="#"
//                         className="read-more-link"
//                         onClick={toggleShowMore}
//                     >
//                         {showMore ? 'Read Less →' : 'Read More →'}
//                     </a>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default ProfileCard;




// Old code backup

// import React, { useState } from 'react';
// import './ProfileCard.css';

// function ProfileCard({ image, img_content }) {
//     const [showMore, setShowMore] = useState(false);

//     const toggleShowMore = (event) => {
//         event.preventDefault();
//         setShowMore(!showMore);
//     };

//     const truncatedContent = img_content.substring(0, 100); // Adjust the number of characters as needed

//     return (
//         <div className="column">
//             <div className="card">
//                 <img src={image} alt="Home page logo" />
//                 <div className="card-content">
//                     <div className="content">
//                         {showMore ? img_content : truncatedContent}
//                         {img_content.length > 100 && (
//                             <>
//                                 <br />
//                                 <a href="#" className="read-more-link" onClick={toggleShowMore}>
//                                     {/* {showMore ? '← Read Less' : 'Read More →'} */}
//                                     {showMore ? 'Read Less' : 'Read More →'}
//                                 </a>
//                             </>
//                         )}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default ProfileCard;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfileCard.css';

function ProfileCard({ image, img_content, title, technologyId }) {
    const navigate = useNavigate();

    const handleReadMore = (event) => {
        event.preventDefault();
        // Navigate to the technologies page with the selected card's ID as a parameter
        navigate(`/technologies/${technologyId}`);
    };

    const truncatedContent = img_content.substring(0, 500); // Adjust as needed

    return (
        <div className="column">
            <div className="card">
                <div className="card-content-horizontal">
                    <div className="content-right">
                        <img src={image} alt={`${title}`} />
                    </div>
                    <div className="content-left">
                        <h3>{title}</h3>
                        <p>{truncatedContent}</p>
                        {/* <p>{img_content}</p> */}
                        <a
                            href={`/technologies/${technologyId}`}
                            className="read-more-link"
                            onClick={handleReadMore}
                        >
                            Read More →
                        </a>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ProfileCard;
