const content = {
    rpa_content: `
Step into the future of efficiency with RPA! Our intelligent automation solutions are designed to eliminate repetitive tasks, reduce human error, and enhance operational productivity. By streamlining workflows, we empower businesses to focus on what matters most—innovation and growth.
    `,
    BigData: 'Unlock the power of data-driven decision-making with our BigData and Cloud Analytics solutions. From managing massive datasets to delivering actionable insights, we help businesses harness the true potential of data in a secure and scalable environment.',
    EDI: 'Streamline your B2B communications with our EDI X12 Converter as a Service. Effortlessly handle complex data exchanges with precision, security, and compliance.',
    AIML: 'Harness the power of artificial intelligence and machine learning to elevate your business processes. Our AIML solutions deliver innovative insights and intelligent automation to keep you ahead in a competitive market.',
    GenAI: 'Revolutionize user experiences with GenAI! Build next-gen applications using frameworks like LangChain, LangGraph, and LangSmith, tailored for creativity, innovation, and immersive interactions.',
    NLP: 'Take communication to the next level with advanced Natural Language Models. Enable smarter interactions and streamline processes with AI-powered text and speech capabilities.',
    MLOps: 'Ensure peak performance and governance for your AI models with our comprehensive MLOps solutions. Monitor, manage, and optimize models to drive continuous innovation and compliance.',
    
    healthcare_services_content: `
        We specialize in Healthcare IT services designed to elevate patient care and operational efficiency through advanced technology.
        Our expertise spans Big Data analytics, enabling deep insights into healthcare data for better decision-making.
        We provide robust Cloud solutions on Azure, AWS, and GCP, ensuring scalable and secure infrastructure.
        Our GenAI applications, including LLM-based solutions, significantly enhance productivity and efficiency, automating complex processes and improving patient outcomes.
        With a focus on innovation, our tailored solutions meet the unique needs of the healthcare industry, driving exceptional care and operational excellence.
    `
};

export default content;


// const content = {
//     rpa_content: "We specialize in Robotic Process Automation (RPA) solutions that streamline operations, enhance productivity, and reduce costs. By automating repetitive tasks, managing data, and improving customer service, we help businesses achieve their goals efficiently. With a focus on the healthcare industry, our tailored, scalable solutions are designed to meet your unique needs, ensuring you stay ahead with innovative technology. Transform your business processes and drive growth with our expert RPA services.",
//     healthcare_services_content: "We specialize in Healthcare IT services designed to elevate patient care and operational efficiency through advanced technology. Our expertise spans Big Data analytics, enabling deep insights into healthcare data for better decision-making. We provide robust Cloud solutions on Azure, AWS, and GCP, ensuring scalable and secure infrastructure. Our GenAI applications, including LLM-based solutions, significantly enhance productivity and efficiency, automating complex processes and improving patient outcomes. With a focus on innovation, our tailored solutions meet the unique needs of the healthcare industry, driving exceptional care and operational excellence.",

//     healthcare_services_content_2: (
//         <p>
//             We specialize in <strong>Healthcare IT services</strong> designed to elevate patient care and operational efficiency through advanced technology. Our expertise spans Big Data analytics, enabling deep insights into healthcare data for better decision-making. We provide robust Cloud solutions on Azure, AWS, and GCP, ensuring scalable and secure infrastructure. Our GenAI applications, including LLM-based solutions, significantly enhance productivity and efficiency, automating complex processes and improving patient outcomes. With a focus on innovation, our tailored solutions meet the unique needs of the healthcare industry, driving exceptional care and operational excellence.
//         </p>
//     ),
//     rpa_content_2: (
//         <p>
//             We specialize in cutting-edge <strong>Robotic Process Automation (RPA)</strong> solutions that automate high-volume, repetitive tasks, freeing up valuable human resources for more strategic activities. Our RPA services go beyond task automation by enabling seamless integration with existing systems, improving data accuracy, and significantly enhancing operational efficiency.
//             <br /><br />
//             With a particular focus on the <strong>healthcare industry</strong>, we design and deploy tailored, scalable RPA frameworks to handle critical processes such as appointment scheduling, claims management, data extraction, and reporting. By incorporating advanced error-handling capabilities and AI-powered decision-making, our RPA solutions not only reduce operational costs but also enhance the quality of customer interactions, ensuring a competitive edge for your organization.
//         </p>
//     )
// };

// export default content

    // rpa_content: "We specialize in Robotic Process Automation (RPA) solutions that streamline operations, enhance productivity, and reduce costs. By automating repetitive tasks, managing data, and improving customer service, we help businesses achieve their goals efficiently. With a focus on the healthcare industry, our tailored, scalable solutions are designed to meet your unique needs, ensuring you stay ahead with innovative technology. Transform your business processes and drive growth with our expert RPA services.",
    // healthcare_services_content: "We specialize in Healthcare IT services designed to elevate patient care and operational efficiency through advanced technology. Our expertise spans Big Data analytics, enabling deep insights into healthcare data for better decision-making. We provide robust Cloud solutions on Azure, AWS, and GCP, ensuring scalable and secure infrastructure. Our GenAI applications, including LLM-based solutions, significantly enhance productivity and efficiency, automating complex processes and improving patient outcomes. With a focus on innovation, our tailored solutions meet the unique needs of the healthcare industry, driving exceptional care and operational excellence.",
