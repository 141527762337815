import React from "react";
import { Link } from "react-router-dom"; // For route navigation
import { HashLink } from "react-router-hash-link"; // For smooth scrolling
import './footer.css';
import footerBackground from '../assets/footer-background.jpg'; // Add the path to your footer background image

const currentYear = new Date().getFullYear();

const Footer = () => {
    return (
        <footer className="contact" style={{ background: `url(${footerBackground}) no-repeat center center`, backgroundSize: 'cover', color: 'white', padding: '20px' }}>
        <div className="footer-columns footer-content">
            <div className="footer-section">
                <h4>Quick Links</h4>
                <ul className='footer-list'>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/Services">Services</Link></li>
                    <li><Link to="/Technologies">Technologies</Link></li>
                    <li><Link to="/about">About Us</Link></li>
                    <li><Link to="/contact">Contact Us</Link></li>
                </ul>
            </div>
            <div className="footer-section">
                <h4>Technologies</h4>
                <ul className='footer-list'>
                    <li><HashLink smooth to="/Technologies#rpa">RPA</HashLink></li>
                    <li><HashLink smooth to="/Technologies#bigdata">BigData</HashLink></li>
                    <li><HashLink smooth to="/Technologies#edi">EDI</HashLink></li>
                    <li><HashLink smooth to="/Technologies#aiml">AIML</HashLink></li>
                    <li><HashLink smooth to="/Technologies#genai">GenAI</HashLink></li>
                    <li><HashLink smooth to="/Technologies#nlp">NLP</HashLink></li>
                    <li><HashLink smooth to="/Technologies#mlops">MLOps</HashLink></li>
                </ul>
            </div>
            <div className="footer-section">
                <h4>Solutions</h4>
                <ul className='footer-list'>
                    <li><HashLink smooth to="/Services/#Cognitive Business Automation"> Cognitive Workflow Innovation</HashLink></li>
                    <li><HashLink smooth to="/Services/#Masters of AI Innovation"> 🚀 AI Powered Innovation  </HashLink></li>
                    <li><HashLink smooth to="/Services/#Advanced Analytics"> Advanced Analytics</HashLink></li>
                    <li><HashLink smooth to="/Services/#EDI Conversion Tool 🚀 Powered By AI"> EDI Conversion Tool 🚀 Powered By AI </HashLink></li>
                    <li><HashLink smooth to="/Services/#Advanced EMR Systems and Integration"> Advanced EMR Systems and Integration </HashLink></li>
                </ul>
            </div>
            <div className="footer-section">
                <h4>Office Locations</h4>
                <p className='location'>
                    <i className='fas fa-map-marker-alt'></i> Corporate Office - Plot No.08, Capital Pk Rd, VIP Hills, Silicon Valley, Madhapur, Hyderabad, Telangana 500081<br />
                    {/* <strong>Phone:</strong> +123 456 7890<br />
                    <strong>Email:</strong> contact@peritose.com */}
                </p>

                <p className='location'>
                    <i className='fas fa-map-marker-alt'></i> 13906 D'Aguilar Highway, NANANGO, QLD state, 4615, Australia <br />
                    {/* <strong>Phone:</strong> +123 456 7890<br />
                    <strong>Email:</strong> contact@peritose.com */}
                </p>
                
                <p className='location'>
                    <i className='fas fa-map-marker-alt'></i> 12100 Singletree Ln, Eden Prairie, MN 55344, United States <br />
                    {/* <strong>Phone:</strong> +123 456 7890<br />
                    <strong>Email:</strong> contact@peritose.com */}
                </p>
                
                <p className='location'>
                    <i className='fa fa-phone phone-icon'></i> Contact: +91 (957)-330-5969 <br />
                </p>
                <p className='location'>
                    <i className='fa fa-envelope email-icon"'></i> Email: admin@peritose.com <br />
                </p>

                {/* <!-- Follow Us Section --> */}
                <div className="follow-us">
                    {/* <p>Follow Us:</p> */}
                    <div className="social-icons">
                        {/* <a href="https://www.facebook.com" target="_blank" class="facebook" data-icon="\f09a"></a>
                        <a href="https://www.twitter.com" target="_blank" class="twitter" data-icon="\f099"></a>
                        <a href="https://www.linkedin.com" target="_blank" class="linkedin" data-icon="\f0e1"></a>
                        <a href="https://www.youtube.com" target="_blank" class="youtube" data-icon="\f167"></a> */}
                        <a href="" target="_blank" class="facebook" data-icon="\f09a"></a>
                        <a href="" target="_blank" class="twitter" data-icon="\f099"></a>
                        <a href="" target="_blank" class="linkedin" data-icon="\f0e1"></a>
                        <a href="" target="_blank" class="youtube" data-icon="\f167"></a>                                
                    </div>
                </div>                        
            </div>
        </div>
        <div className="footer-divider"></div>
        <div className="footer-info">
            <p>Copyright © {currentYear} PERiTOSE Global Solutions, PVT Ltd. All rights reserved.</p>
        </div>
        </footer>
    );
};

export default Footer;