// import React, { useState, useRef, useEffect } from 'react';
// import './Home.css';
// import healthcareImage from '../assets/Innovation-1.jpg';
// import rpa from '../assets/RPA-automation-what-we-do.jpg';
// import pexels from '../assets/pexels-tima-miroshnichenko-6011598.jpg';
// import ProfileCard from './ProfileCard'; // Use ProfileCard as per your existing implementation
// import content from './Content';


// const currentYear = new Date().getFullYear();


// // const Services = () => {
// //   return (
// //     <div>
// //       <h1>What We Offer</h1>
// //       <ul>
// //         <li>RPA Services</li>
// //         <li>AI/ML Solutions</li>
// //         <li>GenAI Products</li>
// //       </ul>
// //     </div>
// //   );
// // };

// const ServiceSection_freeform = () => {
//   const serviceContents_freeform = [
//       { img_content: content.rpa_content, image: rpa },
//       { img_content: content.healthcare_services_content, image: healthcareImage },
//       { img_content: content.rpa_content, image: rpa },
//       { img_content: content.healthcare_services_content, image: healthcareImage }
//   ];

//   return (
//       <div className="services-container-freeform">
//           {serviceContents_freeform.map((item, index) => (
//               <ProfileCard
//                   key={index}
//                   image={item.image}
//                   img_content={item.img_content}
//                   className="profile-card-freeform" // Ensure the profile-card class is applied
//               />
//           ))}
          
//           <footer className="contact">
//             <div className="footer-columns">
//                 <div className="footer-section">
//                     <h4>Quick Links</h4>
//                     <ul className='footer-list'>
//                         <li><a href="/">Home</a></li>
//                         <li><a href="/Services">Services</a></li>
//                         <li><a href="/Technologies">Technologies</a></li>
//                         <li><a href="/about">About Us</a></li>
//                         <li><a href="/contact">Contact Us</a></li>
//                     </ul>
//                 </div>
//                 <div className="footer-section">
//                     <h4>Solutions</h4>
//                     <ul className='footer-list'>
//                         <li><a href="#rpa">RPA</a></li>
//                         <li><a href="#genai">GenAI</a></li>
//                         <li><a href="#data-analytics">Data Analytics on Multicloud</a></li>
//                         <li><a href="#edict">EDICT</a></li>
//                         <li><a href="#emr-ehr">EMR/EHR</a></li>
//                     </ul>
//                 </div>
//                 <div className="footer-section">
//                     <h4>Services</h4>
//                     <ul className='footer-list'>
//                         <li><a href="#consulting">Consulting</a></li>
//                         <li><a href="#implementation">Implementation</a></li>
//                         <li><a href="#support">Support</a></li>
//                         <li><a href="#customization">Customization</a></li>
//                     </ul>
//                 </div>
//                 <div className="footer-section">
//                     <h4>Office Locations</h4>
//                     <p className='location'>
//                         <strong>Head Office:</strong> 123 Your Street, Your City, Your State, Your Country<br />
//                         <strong>Phone:</strong> +123 456 7890<br />
//                         <strong>Email:</strong> contact@peritose.com
//                     </p>
//                     {/* <p>
//                         <strong>Branch Office:</strong> 456 Another Street, Another City, Another State, Another Country<br />
//                         <strong>Phone:</strong> +987 654 3210<br />
//                         <strong>Email:</strong> branch@peritose.com
//                     </p> */}
//                 </div>
//             </div>
//             <div className="footer-divider"></div>
//             <div className="footer-info">
//                 <p>Copyright © {currentYear} PERiTOSE Global Solutions, PVT Ltd. All rights reserved.</p>
//             </div>
//         </footer>

          
//       </div>
//   );
// };

// export default ServiceSection_freeform;

// import React, { useRef, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import './Technologies.css';
// import healthcareImage from '../assets/Innovation-1.jpg';
// import rpa from '../assets/RPA-automation-what-we-do.jpg';

// const Technologies = () => {
//     const serviceContents = [
//         {
//             id: 'rpa',
//             title: 'Robotic Process Automation (RPA)',
//             img_content: (
//                 <p>
//                     Automate repetitive tasks and <strong>increase productivity</strong>. Leverage our scalable RPA
//                     solutions to streamline workflows and <strong>minimize errors</strong>.
//                 </p>
//             ),
//             image: rpa,
//         },
//         {
//             id: 'healthcare',
//             title: 'Healthcare Innovation',
//             img_content: (
//                 <p>
//                     Discover cutting-edge solutions in healthcare technology. Build advanced clinical systems
//                     for <strong>better care</strong> and <strong>smarter workflows</strong>.
//                 </p>
//             ),
//             image: healthcareImage,
//         },
//         // Add more technologies here
//     ];

//     const location = useLocation(); // Get current location for hash navigation
//     const sectionRefs = useRef({}); // Refs for each section

//     useEffect(() => {
//         if (location.hash) {
//             const id = location.hash.substring(1); // Remove "#" from hash
//             const targetSection = sectionRefs.current[id];
//             if (targetSection) {
//                 targetSection.scrollIntoView({ behavior: 'smooth' });
//             }
//         }
//     }, [location]);

//     return (
//         <div className="technologies-container">
//             {serviceContents.map((item) => (
//                 <div
//                     key={item.id}
//                     id={item.id}
//                     className="technology-card"
//                     ref={(el) => (sectionRefs.current[item.id] = el)}
//                 >
//                     <div className="card-content-left">
//                         <h2>{item.title}</h2>
//                         {item.img_content} {/* JSX content rendered here */}
//                     </div>
//                     <div className="card-content-right">
//                         <img src={item.image} alt={item.title} />
//                     </div>
//                 </div>
//             ))}
//         </div>
//     );
// };

// export default Technologies;

// CHange 3

// import React from 'react';
// import { useParams } from 'react-router-dom';
// import './Technologies.css';
// import healthcareImage from '../assets/Innovation-1.jpg';
// import rpa from '../assets/RPA-automation-what-we-do.jpg';
// import bigdata from '../assets/Business_Data_Analytics.jpg';
// import edi from '../assets/EDI.jpg';
// import genai from '../assets/GenAI.jpg';
// import aiml from '../assets/AIML.jpg';
// import mlops from '../assets/MLOps.png';
// import nlp from '../assets/NLP.jpg';

// const Technologies = () => {
//     // Extract the technology ID from the URL
//     const { id } = useParams();

//     // Define the data for each technology
//     const serviceContents = [
//         {
//             id: 'rpa',
//             title: 'Robotic Process Automation (RPA)',
//             img_content: (
//                 <p>
//                     Automate repetitive tasks and <strong>increase productivity</strong>. Our RPA solutions streamline
//                     workflows and <strong>reduce errors</strong>.
//                 </p>
//             ),
//             image: rpa,
//         },
//         {
//             id: 'bigdata',
//             title: 'Big Data & Cloud Analytics',
//             img_content: (
//                 <p>
//                     Harness the power of <strong>Big Data</strong> and the cloud to transform your business. Unlock
//                     insights and improve decision-making with scalable solutions.
//                 </p>
//             ),
//             image: bigdata,
//         },
//         {
//             id: 'edi',
//             title: 'EDI X12 Converter as a Service',
//             img_content: (
//                 <p>
//                     Simplify B2B communication with our <strong>EDI X12 Converter</strong>. Effortlessly handle complex
//                     data exchanges with speed and accuracy.
//                 </p>
//             ),
//             image: edi,
//         },
//         {
//             id: 'aiml',
//             title: 'AIML Solutions',
//             img_content: (
//                 <p>
//                     Leverage <strong>AI and Machine Learning</strong> to stay ahead in the tech landscape. From
//                     predictive models to intelligent automation, we’ve got you covered.
//                 </p>
//             ),
//             image: aiml,
//         },
//         {
//             id: 'genai',
//             title: 'GenAI Application Development',
//             img_content: (
//                 <p>
//                     Explore the possibilities of <strong>Generative AI</strong> with advanced frameworks like LangChain
//                     and LangGraph. Build next-gen applications to revolutionize user experiences.
//                 </p>
//             ),
//             image: genai,
//         },
//         {
//             id: 'nlp',
//             title: 'Natural Language Models',
//             img_content: (
//                 <p>
//                     Transform communication with <strong>Natural Language Models</strong>. From text summarization to
//                     conversational AI, we enable smarter interactions.
//                 </p>
//             ),
//             image: nlp,
//         },
//         {
//             id: 'mlops',
//             title: 'MLOps for Model Monitoring',
//             img_content: (
//                 <p>
//                     Ensure model performance and governance with <strong>MLOps</strong>. We provide seamless monitoring
//                     and optimization for your AI systems.
//                 </p>
//             ),
//             image: mlops,
//         },
//     ];

//     // Find the relevant technology based on the ID
//     const currentTechnology = serviceContents.find((tech) => tech.id === id);

//     // Handle case where the ID doesn't match any technology
//     if (!currentTechnology) {
//         return (
//             <div className="technologies-container">
//                 <h2>Technology Not Found</h2>
//                 <p>The technology you're looking for does not exist. Please check the URL.</p>
//             </div>
//         );
//     }

//     return (
//         <div className="technologies-container">
//             <div className="technology-card">
//                 <div className="card-content-left">
//                     <h2>{currentTechnology.title}</h2>
//                     {currentTechnology.img_content}
//                 </div>
//                 <div className="card-content-right">
//                     <img src={currentTechnology.image} alt={currentTechnology.title} />
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Technologies;

// Change 4

import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './Technologies.css';
import healthcareImage from '../assets/Innovation-1.jpg';
import rpa from '../assets/RPA-automation-what-we-do.jpg';
import bigdata from '../assets/Business_Data_Analytics.jpg';
import edi from '../assets/EDI.jpg';
import genai from '../assets/GenAI.jpg';
import aiml from '../assets/AIML.jpg';
import mlops from '../assets/MLOps.png';
import nlp from '../assets/NLP.jpg';
import footerBackground from '../assets/footer-background.jpg';
import Footer from './footer';

const currentYear = new Date().getFullYear();

const Technologies = () => {
    const { id } = useParams(); // Get the id from the URL
    const sectionRefs = useRef({}); // Refs for scrolling

    // useEffect(() => {
    //     const footerSections = document.querySelectorAll('.footer-section');

    //     const observer = new IntersectionObserver((entries, observer) => {
    //         entries.forEach(entry => {
    //             if (entry.isIntersecting) {
    //                 entry.target.classList.add('active'); // Add 'active' class
    //                 observer.unobserve(entry.target); // Stop observing once triggered
    //             }
    //         });
    //     }, {
    //         threshold: 1, // Trigger when 10% of the footer is visible
    //     });

    //     footerSections.forEach(section => {
    //         observer.observe(section); // Observe each footer section
    //     });

    //     // Cleanup observer on component unmount
    //     return () => observer.disconnect();
    // }, []);

    // Scroll to the specific technology section if an ID is provided
    useEffect(() => {
        if (id && sectionRefs.current[id]) {
            sectionRefs.current[id].scrollIntoView({ behavior: 'smooth' });
        }
    }, [id]);


    // Define all technologies
    const serviceContents = [
        {
            id: 'rpa',
            title: <strong>Robotic Process Automation (RPA)</strong>,
            img_content: (
                <p>
                <br></br>
                Discover the transformative power of RPA in revolutionizing your business operations. Our 
                <strong> Robotic Process Automation</strong> solutions specialize in automating mundane, repetitive 
                tasks, freeing up your workforce to focus on strategic and creative initiatives. Whether it's 
                invoice processing, customer onboarding, or inventory management, we develop scalable RPA 
                workflows that seamlessly integrate into your existing systems.
                <br />
                <br />
                Our expert team ensures that your automation journey is smooth, efficient, and aligned with your 
                business goals. With reduced processing time, minimized errors, and enhanced compliance, 
                <strong> RPA</strong> is your gateway to operational excellence. Let us help you embrace the 
                future of automation!
                </p>

            ),
            image: rpa,
        },
        {
            id: 'bigdata',
            title: <strong>Big Data & Cloud Analytics</strong>,
            img_content: (
                <p>
                <br></br>
                In today’s digital era, <strong>data</strong> is the backbone of every successful organization. 
                Our <strong>BigData and Cloud Analytics</strong> services are designed to help you aggregate, 
                analyze, and visualize vast volumes of data with unparalleled accuracy and efficiency.
                <br />
                <br />
                We specialize in crafting scalable cloud solutions, enabling seamless data migration and 
                management on platforms like <strong>AWS</strong>, <strong>Azure</strong>, and <strong>Google Cloud</strong>. 
                Whether it’s building predictive models, optimizing business intelligence tools, or implementing 
                real-time analytics, we provide end-to-end support tailored to your unique needs.
                <br />
                <br />
                With our expertise, transform raw data into valuable insights that drive smarter, faster 
                decision-making. Stay ahead of the competition by unlocking the true potential of your data.
                </p>
            ),
            image: bigdata,
        },
        {
            id: 'edi',
            title: <strong>EDI X12 Converter as a Service</strong>,
            img_content: (
                <p>
                <br></br>
                <strong>EDI (Electronic Data Interchange)</strong> is the lifeline of seamless communication in 
                industries like healthcare, supply chain, and logistics. Our <strong>EDI X12 Converter</strong> 
                services simplify the processing and exchange of critical data such as healthcare claims, 
                invoices, and shipping notices.
                <br />
                <br />
                We offer a robust, scalable, and secure platform to translate, validate, and transmit EDI 
                documents effortlessly. Our solutions ensure complete compliance with industry standards while 
                optimizing data accuracy and reducing processing times. Whether you're a small business or a 
                large enterprise, our EDI services are designed to meet your unique requirements. Simplify your 
                workflows and enhance operational efficiency with us!
                </p>
            ),
            image: edi,
        },
        {
            id: 'aiml',
            title: <strong>AIML Solutions</strong>,
            img_content: (
                <p>
                <br></br>
                Experience the transformative impact of <strong>AI and ML</strong> in solving real-world business 
                challenges. Our <strong>AIML solutions</strong> span a wide range of applications, including 
                predictive modeling, recommendation systems, fraud detection, and process automation.
                <br />
                <br />
                From data preprocessing to model deployment, we handle every aspect of the AI lifecycle with 
                precision and expertise. With cutting-edge algorithms and a keen understanding of your business 
                needs, we deliver tailor-made solutions that provide actionable intelligence and measurable results. 
                Let us help you redefine innovation and drive success with <strong>AIML</strong>.
              </p>              
            ),
            image: aiml,
        },
        {
            id: 'genai',
            title: <strong>GenAI Application Development</strong>,
            img_content: (
                <p>
                <br></br>
                <strong>Generative AI</strong> is reshaping how we interact with technology, and we are at the 
                forefront of this transformation. Our expertise in frameworks like <strong>LangChain</strong>, 
                <strong>LangGraph</strong>, and <strong>LangSmith</strong> enables us to create powerful AI-driven 
                applications capable of natural language understanding, creative content generation, and workflow automation.
                <br />
                <br />
                From building intelligent chatbots to designing AI systems that generate highly personalized user 
                experiences, our <strong>GenAI solutions</strong> are tailored to your business needs. Whether it's 
                enhancing customer engagement or simplifying complex processes, we bring your ideas to life with 
                <strong>GenAI</strong>.
                </p>
            ),
            image: genai,
        },
        {
            id: 'nlp',
            title: <strong>Natural Language Models</strong>,
            img_content: (
                <p>
                <br></br>
                <strong>Natural Language Models (NLMs)</strong> are at the heart of modern AI applications, enabling 
                human-like interactions and precise understanding of text and speech. We specialize in developing 
                and deploying <strong>NLM solutions</strong> tailored to your specific needs.
                <br />
                <br />
                From creating conversational agents and chatbots to enabling automated document summarization and 
                sentiment analysis, our solutions enhance user engagement and operational efficiency. Whether it’s 
                transforming customer service or improving workflow automation, our <strong>NLM expertise</strong> 
                ensures meaningful impact and lasting results.
                </p>
            ),
            image: nlp,
        },
        {
            id: 'mlops',
            title: <strong>MLOps for Model Monitoring</strong>,
            img_content: (
                <p>
                <br></br>
                <strong>MLOps (Machine Learning Operations)</strong> is critical for the sustained success of AI 
                systems. Our <strong>MLOps solutions</strong> offer robust frameworks for monitoring, deploying, 
                and governing both traditional and large language models (LLMs).
                <br />
                <br />
                We provide end-to-end support, from setting up model pipelines to ensuring compliance with ethical 
                and regulatory standards. With advanced monitoring tools, we track model performance, detect drifts, 
                and implement governance protocols that align with your business objectives. Unlock the full potential 
                of your AI investments with our <strong>MLOps expertise</strong>.
                </p>
            ),
            image: mlops,
        },
    ];

    // // Scroll to the specific technology section if an ID is provided
    // useEffect(() => {
    //     if (id && sectionRefs.current[id]) {
    //         sectionRefs.current[id].scrollIntoView({ behavior: 'smooth' });
    //     }
    // }, [id]);

    return (
        <div className="technologies-container">

            {serviceContents.map((item, index) => (
                <div
                    key={item.id}
                    id={item.id}
                    ref={(el) => (sectionRefs.current[item.id] = el)} // Attach refs for scrolling
                    // className={`technology-card ${index % 2 === 0 ? "reverse" : ""}`} // Add 'reverse' class for alternate layouts
                    className={`technology-card ${index % 2 === 0 ? "reverse" : ""}`} // Alternate layout                    
                >
                    {/* Content and Image Layout */}
                    {/* Alternate Layouts */}                    
                    {index % 2 === 0 ? (
                        <>
                            <div className="card-content-left">
                                <img src={item.image} alt={item.title} />
                            </div>
                            <div className="card-content-right">
                                <h2>{item.title}</h2>
                                {item.img_content}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="card-content-left">
                                <h2>{item.title}</h2>
                                {item.img_content}
                            </div>
                            <div className="card-content-right">
                                <img src={item.image} alt={item.title} />
                            </div>
                        </>
                    )}
                </div>
            ))}

            <Footer/>

        </div>
    );
};

export default Technologies;
