import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../assets/logo/new logo.png';
// import logo from '../assets/logo/logo.png';
import usFlag from '../assets/flags/4x3/us.svg';
import indiaFlag from '../assets/flags/4x3/in.svg';
import auFlag from '../assets/flags/4x3/au.svg';
import './Header.css';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className="header">
            <div className="logo">
                <img src={logo} alt="Company Logo" />
            </div>
            <nav className={`nav ${isOpen ? 'open' : ''}`}>
                <ul>
                    <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
                    <li><Link to="/Services" onClick={toggleMenu}>SERVICES</Link></li>
                    <li><Link to="/Technologies" onClick={toggleMenu}>TECHNOLOGIES</Link></li>
                    <li><Link to="/about" onClick={toggleMenu}>ABOUT US</Link></li>                    
                    <li>
                        <Link to="/contact" onClick={toggleMenu} className="contact-us-link">
                            Contact Us
                        </Link>
                    </li>
                    <li className="mobile-only">
                        <img src={indiaFlag} alt="India Flag" className="flag-icon" />
                        <img src={usFlag} alt="US Flag" className="flag-icon" />
                        <img src={auFlag} alt="AU Flag" className="flag-icon" />
                    </li>
                </ul>
            </nav>
            <div className="flags desktop-only">                
                <img src={indiaFlag} alt="India Flag" className="flag-icon" />
                <img src={usFlag} alt="US Flag" className="flag-icon" />
                <img src={auFlag} alt="AU Flag" className="flag-icon" />
            </div>
                    {/* <li><Link to="/contact" onClick={toggleMenu}>Contact Us</Link></li>
                </ul>
            </nav> */}
            <div className="hamburger" onClick={toggleMenu}>
                {isOpen ? <FaTimes /> : <FaBars />}
            </div>
        </header>
    );
};

export default Header;



// import React from 'react';
// import { Link } from 'react-router-dom';
// import logo from '../assets/logo.png';
// import './Header.css';

// const Header = () => {
//   return (
//     <header>
//       <div className="logo">
//       <img src={logo} alt="Company Logo" />
//       </div>
//       <nav>
//         <ul>
//           <li><Link to="/">Home</Link></li>
//           <li><Link to="/services">What We Offer</Link></li>
//           <li><Link to="/about">Who We Are</Link></li>
//           <li><Link to="/contact">Contact Us</Link></li>
//         </ul>
//       </nav>
//     </header>
//   );
// };

// export default Header;