import React, { useState, useRef, useEffect } from 'react';
import './Home.css';
// import healthcareImage from '../assets/Innovation-1.jpg';
import rpa from '../assets/RPA-automation-what-we-do.jpg';
import bigdata from '../assets/Business_Data_Analytics.jpg';
import edi from '../assets/EDI.jpg';
import genai from '../assets/GenAI.jpg';
import aiml from '../assets/AIML.jpg';
import mlops from '../assets/MLOps.png'
import nlp from '../assets/NLP.jpg'
// import pexels from '../assets/pexels-tima-miroshnichenko-6011598.jpg';
// import pexels from '../assets/healthcare_it.jpg';
import pexels from '../assets/HC_Img.jpeg';
import ProfileCard from './ProfileCard'; // Use ProfileCard as per your existing implementation
import content from './Content';
import { motion, AnimatePresence } from 'framer-motion';
import Footer from './footer';
import Solutions from './Services';

const currentYear = new Date().getFullYear();

const ServiceSection = () => {
    const sectionRef = useRef(null);
    const [cardWidth, setCardWidth] = useState(0); // Updated card width

    useEffect(() => {
        const updateCardWidth = () => {
            if (sectionRef.current) {
                const numCards = sectionRef.current.children.length;
                const containerWidth = sectionRef.current.clientWidth;
                setCardWidth(containerWidth / Math.min(numCards, 3)); // Show up to 3 cards at a time
            }
        };
        updateCardWidth();
        window.addEventListener('resize', updateCardWidth);
        return () => window.removeEventListener('resize', updateCardWidth);
    }, []);

    // const serviceContents = [
    //     { img_content: content.rpa_content, image: rpa },
    //     { img_content: content.BigData, image: bigdata },
    //     { img_content: content.EDI, image: edi },
    //     { img_content: content.AIML, image: aiml },
    //     { img_content: content.GenAI, image: genai },
    //     { img_content: content.NLP, image: nlp },
    //     { img_content: content.MLOps, image: mlops },
    //     // { img_content: content.rpa_content, image: rpa },
    //     // { img_content: content.healthcare_services_content, image: healthcareImage },
    //     // { img_content: content.rpa_content, image: healthcareImage },
    //     // { img_content: content.rpa_content, image: rpa },
    //     // { img_content: content.healthcare_services_content, image: healthcareImage },
    //     // { img_content: content.rpa_content, image: rpa },
    //     // { img_content: content.healthcare_services_content, image: healthcareImage },
    //     // { img_content: content.rpa_content, image: healthcareImage }
    // ];

    // Define serviceContents with technologyId for navigation
    const serviceContents = [
        {
            id: 'rpa',
            title: 'Robotic Process Automation (RPA)',
            img_content: content.rpa_content,
            image: rpa,
        },
        {
            id: 'bigdata',
            title: 'Big Data & Cloud Analytics',
            img_content: content.BigData,
            image: bigdata,
        },
        {
            id: 'edi',
            title: 'EDI X12 Converter as a Service',
            img_content: content.EDI,
            image: edi,
        },
        {
            id: 'aiml',
            title: 'AIML Solutions',
            img_content: content.AIML,
            image: aiml,
        },
        {
            id: 'genai',
            title: 'GenAI Application Development',
            img_content: content.GenAI,
            image: genai,
        },
        {
            id: 'nlp',
            title: 'Natural Language Models',
            img_content: content.NLP,
            image: nlp,
        },
        {
            id: 'mlops',
            title: 'MLOps for Model Monitoring',
            img_content: content.MLOps,
            image: mlops,
        },
    ];

    return (
        <div className="services-container">
            <motion.section
                className="services"
                ref={sectionRef}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                style={{ overflowX: 'auto', display: 'flex' }} // Ensure overflow and flex display
            >
                {serviceContents.map((item, index) => (
                    <ProfileCard
                        key={index}
                        technologyId={item.id} // Pass the ID to navigate
                        image={item.image}
                        img_content={item.img_content}
                        title={item.title} // Display the title
                        className="profile-card"                        
                    />
                ))}
            </motion.section>
            <div className="button-container">
                <button type="button" className="slick-arrow" onClick={() => sectionRef.current.scrollBy({ left: -cardWidth, behavior: 'smooth' })}>
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                </button>
                <button type="button" className="slick-arrow" onClick={() => sectionRef.current.scrollBy({ left: cardWidth, behavior: 'smooth' })}>
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    );
};

const Home = () => {
    const [contentIndex, setContentIndex] = useState(0);


    useEffect(() => {
        const footerSections = document.querySelectorAll('.footer-section');

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active'); // Add 'active' class
                    observer.unobserve(entry.target); // Stop observing once triggered
                }
            });
        }, {
            threshold: 1, // Trigger when 10% of the footer is visible
        });

        footerSections.forEach(section => {
            observer.observe(section); // Observe each footer section
        });

        // Cleanup observer on component unmount
        return () => observer.disconnect();
    }, []);
    

    const homeContents = [
        {
            title: '\u2E3A Innovative Application Solutions', // Unicode U+2015 (Horizontal Bar), Two-em dash (U+2E3A)
            description: 'Revolutionizing Technology for Enhanced Efficiency and Better Outcomes',
            paragraph: "We deliver intelligent technology solutions that empower organizations to thrive. By leveraging cutting-edge innovations, we help businesses optimize processes, improve collaboration, and achieve measurable success.",
            link: '/Services',
            linkText: 'Our Services',
            image: {pexels}
        },
        {
            title: '\u2E3A Driving Progress through Technology',
            description: 'Empowering Businesses with Advanced IT Systems for Better Productivity',
            paragraph: "Our state-of-the-art solutions enable businesses to harness the full potential of innovative IT systems. From boosting productivity to enhancing operational capabilities, our offerings are tailored to support strategic growth and scalable efficiency.",
            link: '/Technologies',
            linkText: 'Our Technologies',
            image: {pexels}
        }
    ];

    const nextContent = () => {
        setContentIndex((prevIndex) => (prevIndex + 1) % homeContents.length);
    };

    const prevContent = () => {
        setContentIndex((prevIndex) => (prevIndex - 1 + homeContents.length) % homeContents.length);
    };

    // Automatically transition every few seconds
    useEffect(() => {
        const interval = setInterval(() => {
            // nextContent(); // Navigate to the next page every few seconds
            setContentIndex((prevIndex) => (prevIndex + 1) % homeContents.length);            
        }, 5000); // Adjust the interval (in milliseconds) as needed, e.g., 5000ms = 5 seconds

        return () => clearInterval(interval); // Clear the interval on component unmount
    // }, []); // Empty dependency array ensures this effect runs once when the component mounts
    }, [homeContents.length]);
   
    return (
        <div className="home-container">
            <motion.section
                className="hero"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
            >
                <div className="slider-container">
                    {/* <img src={pexels} alt="Hero" className="slider-image" />

                    <button type="button" className="slick-prev slick-arrow" onClick={prevContent}>
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                    </button>

                    <div className="content">
                        <span>{homeContents[contentIndex].title}</span>
                        <h1>{homeContents[contentIndex].description}</h1>
                        <p>{homeContents[contentIndex].paragraph}</p>
                        <div className="hero__btn">
                            <a href={homeContents[contentIndex].link} className="btn hero-btn">{homeContents[contentIndex].linkText} <i className="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                        </div>
                    </div> */}

                    <motion.img
                        // src={homeContents[contentIndex].image}
                        src={pexels}
                        alt="Hero"
                        className="slider-image"
                        initial={{ scale: 0.8, opacity: 0, x: -50  }}
                        animate={{ scale: 1, opacity: 1, x: 0 }}
                        transition={{ duration: 1.2 }}
                    />

                    <button
                        type="button"
                        className="slick-prev slick-arrow"
                        onClick={prevContent}
                    >
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                    </button>

                    {/* Content Transition with AnimatePresence */}
                    <AnimatePresence>
                        <motion.div
                            key={contentIndex}
                            className="content"
                            initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 50 }}
                            transition={{ duration: 1 }}
                        >
                            <motion.span
                                initial={{ opacity: 0, x: -50 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ delay: 0.2, duration: 1 }}
                            >
                                {homeContents[contentIndex].title}
                            </motion.span>

                            <motion.h1
                                initial={{ opacity: 0, x: -50 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ delay: 0.4, duration: 1 }}
                            >
                                {homeContents[contentIndex].description}
                            </motion.h1>

                            <motion.p
                                initial={{ opacity: 0, x: -50 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ delay: 0.6, duration: 1 }}
                            >
                                {homeContents[contentIndex].paragraph}
                            </motion.p>

                            <motion.div
                                className="hero__btn"
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ delay: 0.8, duration: 1 }}
                            >
                                <a
                                    href={homeContents[contentIndex].link}
                                    className="btn hero-btn"
                                >
                                    {homeContents[contentIndex].linkText}{' '}
                                    <i
                                        className="fa fa-long-arrow-right"
                                        aria-hidden="true"
                                    ></i>
                                </a>
                            </motion.div>
                        </motion.div>
                    </AnimatePresence>

                    <button type="button" className="slick-next slick-arrow" onClick={nextContent}>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                </div>
            </motion.section>

            <ServiceSection />

            {/* <footer className="contact">                 */}
                {/* <p> Copyright © {currentYear} PERiTOSE Global Solutions, PVT Ltd.</p> */}
            {/* </footer> */}

            {/* <footer className="contact"> */}
            <Footer />
        </div>
    );
};

export default Home;
