import 'bulma/css/bulma.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
// import Services from './components/Services';
// import About from './components/About';
import Services from './components/Services';
import Technologies from './components/Technologies';
import About from './components/AboutUs';
import Contact from './components/Contact';
import LoadingLogo from './assets/logo/Bhavani Final Work v2.mp4'

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the time as needed
  }, []);


// function App() {
  return (
    <Router>
      <ScrollToTop/>
      <div className="App">
        {isLoading ? (
          <div className='loading-screen'>
            <div className='loading-logo-container'>
              <video className='loading-logo' autoPlay loop muted>
                <source src={LoadingLogo} type='video/mp4'/>
              </video>
            </div>
          </div>
        ) : (
          <>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Services" element={<Services />} />
              <Route path="/Technologies" element={<Technologies />} />
              <Route path="/Technologies/:id" element={<Technologies />} /> {/* Dynamic route */}              
              <Route path="/About" element={<About />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>          
          </>
        )}
      </div>
    </Router>
  );
};
// };

export default App;
